// External Dependencies
import {
  Box,
  Button,
  Paper,
  Typography,
  FormControl,
  Grid,
} from "@mui/material/";
import { styled } from "@mui/system";

const mobileBreakPoint = "900px";

export const QuestionsContainer = styled(Typography)`
  display: flex;
  margin-top: 40px;
`;
export const FilterContainer = styled("div")`
  display: none;
  @media (min-width: ${mobileBreakPoint}) {
    display: block;
    width: 200px;
    padding: 10px 20px 20px;
  }
`;
export const FilterControl = styled(FormControl)`
  margin-bottom: 10px;
`;
export const FilterButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border: ${(props) =>
    props.$active ? `3px solid ${props.$borderColor}` : "3px solid #ccc"};
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 10px;
  font-weight: 600;
  text-transform: capitalize;
`;
export const FilterButtonIcon = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  padding: 5px;
  width: 20px;
  height: 20px;
  border-radius: 40px;
  margin-right: 10px;
`;
export const ChartSection = styled("div")`
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  width: calc(100%);
  background-color: #f3f9fc;
  padding: 20px;
  border-radius: 20px;
  @media (min-width: ${mobileBreakPoint}) {
    width: calc(100% - 200px);
  }
`;
export const DemographicCard = styled(Paper)`
  display: inline-block;
  position: relative;
  width: calc(100% - 20px);
  min-height: 500px;
  margin: 10px;
  border-radius: 10px;
  @media (min-width: ${mobileBreakPoint}) {
    width: calc(33% - 18px);
  }
`;
export const LegendRow = styled("div")`
  display: flex;
  align-items: flex-start;
  font-weight: 600;
`;
export const LegendSquare = styled("div")`
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: ${(props) => props.$backgroundColor};
  margin: 8px 10px 0 0;
`;
export const LegendSpace = styled("div")`
  display: inline-block;
  margin: 0px auto 0px auto;
`;

export const ChartJSContainer = styled("div")`
  position: relative;
  width: calc(100% - 60px);
  height: 350px;
  margin: 120px 0 0 50px;
  border-radius: 20px;
  @media (min-width: ${mobileBreakPoint}) {
    margin-top: 60px;
  }
`;
export const QuestionCard = styled(Paper)`
  display: inline-block;
  position: relative;
  width: calc(100% - 60px);
  height: 475px;
  margin: 10px;
  padding: 20px;
  border-radius: 20px;
  @media (min-width: ${mobileBreakPoint}) {
    height: 400px;
  }
`;
export const ChartControls = styled("div")`
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
`;

export const KPIContainer = styled("div")`
  display: flex;
  width: calc(100% - 400px);
  justify-content: flex-start;
  margin-top: 20px;
`;
export const KPI = styled("div")`
  position: relative;
  text-transform: uppercase;
  margin-right: 80px;
`;
export const KPILabel = styled(Typography)`
  color: #bdbdbd;
  font-size: 11px;
  font-weight: 700;
  margin-bottom: 5px;
`;
export const KPIValue = styled("div")`
  position: absolute;
  top: 22px;
  left: 27px;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 50px;
`;
export const Headline = styled(Typography)`
  margin-bottom: 10px;
  font-weight: 600;
  letter-spacing: 1.1px;
`;
export const GreyLine = styled("div")`
  margin: 5px;
  background-color: #e8e8e8;
  height: 1px;
  display: flex;
`;

export const ModalButtons = styled("div")`
  position: absolute;
  bottom: 0;
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
`;
export const ResultsButton = styled(Button)`
  margin: 20px;
  border-radius: 50px;
  font-weight: 700;
  text-transform: none;
  letter-spacing: 1.1px;
  border: 2px solid;
`;
export const MobileFilterButton = styled(ResultsButton)`
  display: block;
  @media (min-width: ${mobileBreakPoint}) {
    display: none;
  }
`;
export const FilterModal = styled(Box)`
  position: fixed;
  left: 25px;
  top: 100px;
  display: block;
  width: calc(100% - 90px);
  height: calc(100% - 200px);
  padding: 20px;
  border-radius: 20px;
  background: #fff;
`;

export const QuestionLabel = styled(Typography)`
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 20px;
  font-weight: 700;
`;
export const Question = styled(Typography)`
  position: absolute;
  top: 12px;
  left: 70px;
  width: calc(100% - 125px);
`;
export const ViewOptions = styled("div")`
  display: flex;
  justify-content: space-between;
`;
