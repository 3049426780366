// External Dependencies
import React, { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Backdrop,
  Button,
  Container,
  Box,
  SwipeableDrawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  Avatar,
  CardHeader,
  Modal,
  Grid,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material/";
import {
  AccountCircle,
  AccessAlarm,
  BarChart,
  CloudDownload,
  FeaturedPlayList,
  Feedback,
  PieChart,
  TableChart,
} from "@mui/icons-material/";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import FilterListIcon from "@mui/icons-material/FilterList";
import PeopleIcon from "@mui/icons-material/People";
import EventIcon from "@mui/icons-material/Event";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ScheduleIcon from "@mui/icons-material/Schedule";
import StarIcon from "@mui/icons-material/Star";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { styled } from "@mui/system";
import { saveAs } from "file-saver";
import { useTheme } from "@mui/material/styles";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

// Internal Dependencies
import ChartJS from "../../components/ChartJS/";
import { axiosCall } from "../../utils/api";

import {
  QuestionsContainer,
  ChartSection,
  DemographicCard,
  LegendRow,
  LegendSquare,
  LegendSpace,
  QuestionCard,
  ChartJSContainer,
  ChartControls,
  KPIContainer,
  KPI,
  KPILabel,
  KPIValue,
  FilterHeader,
  FilterContainer,
  FilterControl,
  FilterButton,
  FilterButtonIcon,
  FilterModal,
  Headline,
  GreyLine,
  ModalButtons,
  ResultsButton,
  MobileFilterButton,
  Question,
  QuestionLabel,
  ViewOptions,
} from "./styles.js";

export default function ResultsDashboard(props) {
  // 0. Grab the theme
  const theme = useTheme();

  // 1. Setup States
  const { engagementId } = useParams();
  const [kpis, setKpis] = React.useState([]);
  const [survey, setSurvey] = React.useState([]);
  const [questions, setQuestions] = React.useState([]);
  const [demographics, setDemographics] = React.useState([]);
  const [questionsActive, setQuestionsActive] = React.useState(true);
  const [filters, setFilters] = React.useState({});
  const [mobileFiltersDisplay, setMobileFiltersDisplay] = React.useState(false);

  // 2. Setup Handlers
  const handleChartChange = async (type, index) => {
    // console.log(Object.keys(demographics)[index])
    // console.log(demographics[Object.keys(demographics)[index]])
    let newDemographic = Object.values(demographics)[index];
    newDemographic.chartType = type

    let newDemographics = demographics
    newDemographics[Object.keys(demographics)[index]] = newDemographic
    // console.log(demographics[Object.keys(demographics)[index]] )
    await setDemographics(newDemographics);


  };

  const handleFilterChange = async (e, filters, field) => {
    filters[field]["inputs"] = e.target.value;
    await setFilters(filters);
    getData();
  };

  const handleChangeViewType = (view) => {
    setQuestionsActive(view === "questions");
  };

  const toggleMobileFilters = () => {
    setMobileFiltersDisplay(!mobileFiltersDisplay);
  };

  // 3.
  const getData = async () => {
    // 1. Grab the data, once
    const results = await axiosCall(
      false,
      "get",
      "survey-results",
      engagementId,
      {
        filters
      }
    );

    // 2. Deconstruct the data and set variables
    // console.log( results.data.data)
    const {
      survey,
      kpis,
      questions,
      demographics,
      filters: filtersData,
      ...rest
    } = results.data.data;


    // 3. Build KPIS TODO
    await setKpis(kpis);
    await setSurvey(survey);
    await setQuestions(questions);
    await setDemographics(demographics);
    await setFilters(filtersData);
  };

  // 4. Custom function to build CSV files
  const downloadToCSV = () => {
    // const blob = new Blob([csvString], { type: "text/plain;charset=utf-8" });
    // saveAs(blob, `${survey.title}-Results.csv`);
  };

  // 4.2 Transform JSON to Charts
  const kpiSection = Object.keys(kpis).map((kpiLabel) => {
    return (
      <KPI>
        <KPILabel>{kpiLabel}</KPILabel>
        <PeopleIcon color="disabled" />
        <KPIValue>{kpis[kpiLabel]}</KPIValue>
      </KPI>
    );
  });

  const dataSet = questionsActive ? questions : demographics;
  const chartComponents = Object.keys(dataSet).map((key, i) => {
    // 1. De-construct questionType
    const { questionType, chartType } = dataSet[key];

    // 2. This format is for Chart JS
    const labels = Object.keys(dataSet[key]["data"]);
    const data = Object.values(dataSet[key]["data"]);

    // 3. Swap between the full view or partial
    const CardDynamic = questionsActive ? QuestionCard : DemographicCard;

    const maxTitleLength = 200;
    const title = questionsActive ? (
      <>
        <QuestionLabel variant="span">Q.{i + 1}</QuestionLabel>
        <Question variant="span">
          {key.length > maxTitleLength ? `${key.substring(0, maxTitleLength)} ...` : key}
        </Question>
      </>
    ) : (
      <QuestionLabel variant="span">{key}</QuestionLabel>
    );

    const detailsOptions = questionsActive ? (
      <div />
    ) : (
      <>
        <br />
        <Accordion>
          <AccordionSummary
            expandIcon={<PlayCircleIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>View Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {Object.keys(dataSet[key]["data"]).map((label, i) => {
              const legendValue = dataSet[key]["data"][label];
              const percentage = Math.round(
                (legendValue / Object.keys(dataSet[key]["data"]).length) * 100
              );

              return (
                <LegendRow>
                  <LegendSquare $backgroundColor={theme.palette[`chart${i % 4}`].main} />
                  <Typography variant="body">{label}</Typography>
                  <LegendSpace />
                  <Typography variant="span">{legendValue} | </Typography>
                  <Typography variant="span">{percentage}% </Typography>
                </LegendRow>
              );
            })}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<PlayCircleIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Your View Option
          </AccordionSummary>

          <AccordionDetails>
            <ViewOptions>
              <BarChart color="chart0" onClick={() => {
                handleChartChange("bar", i);
              }} />
              <PieChart
                color="chart1"
                onClick={() => {
                  handleChartChange("pie", i);
                }}
              />
              <TableChart
                color="chart2"
                onClick={() => {
                  handleChartChange("table", i);
                }}
              />
            </ViewOptions>
          </AccordionDetails>
        </Accordion>
      </>
    );

    if(chartType === 'word-chart'){
      return (<CardDynamic>
        {title}

        <ChartJSContainer>
        {labels}
        </ChartJSContainer>

        {detailsOptions}
      </CardDynamic>)
    }
    return (
      <CardDynamic>
        {title}

        <ChartJSContainer>
          <ChartJS type={chartType} labels={labels} data={data} />
        </ChartJSContainer>
        {detailsOptions}
      </CardDynamic>
    );
  });

  useEffect(() => {
    getData();
  }, []);

  const filtersHTML = Object.keys(filters).map((filterKey) => {
    return (
      <FilterControl fullWidth>
        <InputLabel id={`${filterKey}-label-id`}>{filterKey}</InputLabel>
        <Select
          labelId={`${filterKey}-label-id`}
          id={filterKey}
          value={''}
          label={filterKey}
          onChange={(e) => handleFilterChange(e, filters, filterKey)}

        >
          {/*multiple This enables multi select but the values don't really show or trigger states properly */}
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {filters[filterKey]["options"].map((answer) => {
            return <MenuItem value={answer}>{answer}</MenuItem>;
          })}
        </Select>
      </FilterControl>
    );
  });

  return (
    <Box>
      <Container maxWidth="xl">
        <Link style={{ textDecoration: "none" }} to={`/dashboard`}>
          <Button>
            <ArrowBackIcon />
            <b>Back to dashboard</b>
          </Button>
        </Link>
        <Headline variant={"h3"}>{survey.title}</Headline>
        <Headline variant={"h5"}>
          Explore the data and insights from your current engagement
        </Headline>
        <GreyLine />
        <Grid container>
          <ResultsButton onClick={downloadToCSV}>
            <DownloadIcon />
            Download Results
          </ResultsButton>
          <MobileFilterButton onClick={toggleMobileFilters}>
            <FilterListIcon />
            Filters
          </MobileFilterButton>
          <KPIContainer>{kpiSection}</KPIContainer>
        </Grid>
        <GreyLine />

        <QuestionsContainer>
          <FilterContainer>
            <Typography variant="h5">Data View</Typography>
            <br />
            <FilterButton
              $active={questionsActive}
              $borderColor={theme.palette.primary.main}
              onClick={() => {
                handleChangeViewType("questions");
              }}
            >
              <FilterButtonIcon>
                <CheckCircleOutlineIcon />
              </FilterButtonIcon>
              All Questions
            </FilterButton>
            <FilterButton
              $active={!questionsActive}
              $borderColor={theme.palette.primary.main}
              onClick={() => {
                handleChangeViewType("demographics");
              }}

            >
              <FilterButtonIcon>
                <VisibilityIcon />
              </FilterButtonIcon>{" "}
              Demographics
            </FilterButton>

            <Typography variant="h5">Filters</Typography>
            <br />
            {filtersHTML}
          </FilterContainer>
          <ChartSection>{chartComponents}</ChartSection>
        </QuestionsContainer>
      </Container>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={mobileFiltersDisplay}
        onClose={toggleMobileFilters}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <FilterModal>
          <Typography variant="h5">Filters</Typography>
          <br />
          {filtersHTML}
          <ModalButtons>
            <ResultsButton>Cancel</ResultsButton>
            <ResultsButton>Okay</ResultsButton>
          </ModalButtons>
        </FilterModal>
      </Modal>
    </Box>
  );
}
