// External Dependencies
import React, { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Backdrop,
  Button,
  Container,
  Box,
  SwipeableDrawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  Avatar,
  CardHeader,
  Modal,
  Grid,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material/";
import {
  AccountCircle,
  AccessAlarm,
  BarChart,
  CloudDownload,
  FeaturedPlayList,
  Feedback,
  PieChart,
  TableChart,
} from "@mui/icons-material/";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import FilterListIcon from "@mui/icons-material/FilterList";
import PeopleIcon from "@mui/icons-material/People";
import EventIcon from "@mui/icons-material/Event";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ScheduleIcon from "@mui/icons-material/Schedule";
import StarIcon from "@mui/icons-material/Star";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { styled } from "@mui/system";
import { saveAs } from "file-saver";
import { useTheme } from "@mui/material/styles";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

// Internal Dependencies
import { axiosCall } from "../../utils/api";


export default function Sandbox(props) {
  // 0. Grab the theme
  const theme = useTheme();

  const getData = async () => {
    // 1. Grab the data, once
    const results = await axiosCall(
      true,
      "get",
      "status",
      
    );

  };


  useEffect(() => {
    console.log('use effect')
    getData();
  }, []);


  return (
    <Box>
    </Box>
  );
}
