// External Dependencies
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  Container,
  Box,
  Drawer,
  SwipeableDrawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  AttachMoney,
  AccountCircle,
  ExitToApp,
  FeaturedPlayList,
  Feedback,
} from "@mui/icons-material";
import { styled } from "@mui/system";

// Internal Dependencies
import { showHamburgerIcon } from "../../utils/layout";
import { axiosCall } from "../../utils/api";

// Styles
const HamburgerButton = styled("div")`
  position: fixed;
  top: -32px;
  right: 15px;
  z-index: 1201;
  cursor: pointer;
  display: block;
`;

const HamburgerMenu = () => {
  const location = useLocation();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const anchor = "right"; // can also be top | bottom | left

  const logout = async (e, href) => {
    const results = await axiosCall(true, "post", "logout");
    window.location.href = "/login"; //TODO should be using a react router here
  };

  const navItems = [
    {
      text: "Dashboard",
      icon: <FeaturedPlayList />,
      href: "/dashboard",
    },
    // {
    //   text: "Earnings",
    //   icon: <AttachMoney />,
    //   href: "/earnings",
    // },
    // {
    //   text: "Profile",
    //   icon: <AccountCircle />,
    //   href: "/profile",
    // },
  ];
  const helpNavItem = [
    // {
    //   text: "Help",
    //   icon: <Feedback />,
    //   href: "/help",
    // },
    {
      text: "Logout",
      icon: <ExitToApp />,
      href: "/login",
      navFunction: "logout",
    },
  ];

  const handleOnClick = (e, href, navFunction) => {
    if (navFunction === 'logout') {
      logout(e, href);
    } else {
      window.location.href = `${href}`;
    }
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {navItems.map(({ text, icon, href, navFunction }, index) => (
          <ListItem
            button
            key={text}
            onClick={(e) => {
              handleOnClick(e, href, navFunction);
            }}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {helpNavItem.map(({ text, icon, href, navFunction }, index) => (
          <ListItem
            button
            key={text}
            onClick={(e) => {
              handleOnClick(e, href, navFunction);
            }}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  if (!showHamburgerIcon(location.pathname)) return <React.Fragment />;

  return (
    <React.Fragment key={anchor}>
      <HamburgerButton
        id="Keyops__hamburgerButton"
        className={`${state[anchor] ? "open" : ""}`}
        onClick={toggleDrawer(anchor, !state[anchor])}
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </HamburgerButton>
      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
      >
        {list(anchor)}
      </Drawer>
    </React.Fragment>
  );
};

export default HamburgerMenu;
