// External Dependencies
import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Paper,
  Typography,
} from "@mui/material/";

// Internal Dependencies

// Styled Components
const SurveyCardStyle = styled(Card)`
  width: 300px;
  margin: 8px;
`;


export default function SurveyCard({ linkId, title, payout, ...rest }) {
  return (
    <SurveyCardStyle>
      <Link to={`/surveys/${linkId}`}>
        <CardContent>
          {/*<Typography color="text.secondary" gutterBottom>
            Word of the Day
          </Typography>*/}
          <Typography variant="h5" component="div">
            {title}
          </Typography>
          {/*<Typography color="text.secondary">adjective</Typography>
          <Typography variant="body2">
            well meaning and kindly.
            <br />
            {'"a benevolent smile"'}
          </Typography>*/}
        </CardContent>
        <CardActions>
          {/*}<Button size="small">Learn More</Button>*/}
        </CardActions>
      </Link>
    </SurveyCardStyle>
  );
}
