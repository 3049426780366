// External Dependencies
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Button,
  Container,
  Box,
  SwipeableDrawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material/";
import {
  AttachMoney,
  AccountCircle,
  FeaturedPlayList,
  Feedback,
  MoreHoriz,
  BarChart,
  PieChart,
  TableChart,
  Cancel,
  Image
} from "@mui/icons-material";
import DownloadIcon from '@mui/icons-material/Download';

import { useTheme } from "@mui/material/styles";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";
import { styled } from "@mui/system";

// Internal Dependencies

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);


const ChartActionMenu = styled("div")`
  position: absolute;
  top: -70px;
  right: -20px;
  cursor: pointer;
  overflow: hidden;
  height: ${(props) =>
    props.$open ? `auto` : "27px"};
`;
const ChartAction = styled("div")`
  width: 25px;
  height: 25px;
  border: 1px solid #ccc;
  &:hover {
    border: 1px solid #000;    
  }
`;

// TODO needs code cleaning check and commenting
const ChartJS = ({ type, labels, data }) => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const theme = useTheme();
  const chartRef = useRef(null);


  useEffect(() => {
    const chartJSData = {
      labels,
      datasets: [
        {
          data,
          // TODO, look for a dynamic solution
          backgroundColor: [
            theme.palette.chart0.main,
            theme.palette.chart1.main,
            theme.palette.chart2.main,
            theme.palette.chart3.main,
          ],
          fill: false,
        },
      ],
    };

    const options = {
      title: {
        display: true,
        // text: "World population per region (in millions)",
      },
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false, //type !== "bar",
        },
      },
      scales: {
        x: {
          display: type === "bar",
          grid: {
            display: false,
          },
          ticks: {
            callback: (value, index, ticks) => {
              return type === "bar"
                ? String(labels[index]).substring(0, 15)
                : null;
            },
          },
        },
        y: {
          display: type === "bar",
          grid: {
            display: false,
          },
        },
      },
      responsive: true,
    };
    const myChart = new Chart(chartRef.current, {
      type,
      data: chartJSData,
      options,
    });

  }, []);

  const dlChartAsImage = () => {
    const strWindowFeatures = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
    const URL = chartRef.current.toDataURL('image/png', 1);;
    const win = window.open(URL, "_blank", strWindowFeatures);
  }

  const toggleMenu = () => {
    setOpenMenu(!openMenu)
  }
  const changeChartType = () => {

  }


  return (<>
    <canvas ref={chartRef}></canvas>
    <ChartActionMenu $open={openMenu}>
      <ChartAction onClick={toggleMenu}><MoreHoriz color="primary"/></ChartAction>
      <ChartAction onClick={changeChartType}><BarChart color="primary"/></ChartAction>
      <ChartAction onClick={changeChartType}><PieChart color="primary"/></ChartAction>
      <ChartAction onClick={changeChartType}><TableChart color="primary"/></ChartAction>
      <ChartAction onClick={dlChartAsImage}><Image color="primary"/></ChartAction>
      <ChartAction onClick={()=>{}}><DownloadIcon color="primary"/></ChartAction>
      <ChartAction onClick={toggleMenu}><Cancel color="primary"/></ChartAction>
    </ChartActionMenu>
    </>);
};

export default ChartJS;
