// External Dependencies
import * as React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Typography, img } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputUnstyled, { inputUnstyledClasses } from "@mui/base/InputUnstyled";

import axios from "axios";

// Internal Dependencies
import lang from "../../utils/lang";
import {applicationId} from "../../utils/constants";
import { axiosCall } from "../../utils/api";
import {
  StyledInputRoot,
  StyledInputElement,
  PageContainer,
  WelcomeMessage,
  InputAdornment,
  IconButton,
  Pad,
} from "./styles.js";
import Logo from "../../images/KeyOpsLogo.svg";

// App Strings
const appText = lang[navigator.language];

const CustomInput = React.forwardRef(function CustomInput(props, ref) {
  const { components, ...other } = props;
  return (
    <InputUnstyled
      components={{
        Root: StyledInputRoot,
        Input: StyledInputElement,
        ...components,
      }}
      {...other}
      ref={ref}
    />
  );
});

CustomInput.propTypes = {
  /**
   * The components used for each slot inside the InputBase.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    Input: PropTypes.elementType,
    Root: PropTypes.elementType,
    Textarea: PropTypes.elementType,
  }),
};

export default function Login() {
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
    errorText: "",
  });

  const navigate = useNavigate();

  const handleChange = async (event, field) => {
    // console.log(field, event.keyCode, event.target)
    // console.log(values)
    await setValues({ ...values, [field]: event.target.value });

    if (event.keyCode === 13) {
      signIn();
    }
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const signIn = async () => {
    const { email, password } = values;

    try {
      const results = await axiosCall(true, "post", "login", false, false, {
        email,
        password,
        application: applicationId,
      });

      localStorage.setItem('Keyops__refreshToken', results.data.data.KeyOps__refreshToken)

      navigate(`/dashboard`);
    } catch (e) {
      setValues({
        ...values,
        errorText:
          // TODO add to lang file
          "There was a problem with your login, please check your credentials",
      });
    }
  };
  // TODO, this very basic pattern isn't working for some reason
  // value={values.email}
  // value={values.password}
  return (
    <PageContainer style={{ textAlign: "center" }}>
      <Pad>
        <img style={{ height: 60 }} src={Logo} />
      </Pad>
      <Pad>
        <Typography>
          Hey there, welcome to KeyOps Results Console. Please sign in
        </Typography>
      </Pad>
      <CustomInput
        placeholder="User Name"
        type={"text"}
        onKeyUp={(e) => {
          handleChange(e, "email");
        }}
      />
      <CustomInput
        placeholder="Password"
        id="password"
        type={values.showPassword ? "text" : "password"}
        onKeyUp={(e) => {
          handleChange(e, "password");
        }}
        endAdornment={
          <InputAdornment>
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {values.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />

      <Typography>{values.errorText}</Typography>

      <Button variant={'contained'} onClick={signIn}> {appText.general.signIn} </Button>
    </PageContainer>
  );
}
